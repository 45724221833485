import { defineStore } from 'pinia'

export const useAreaStore = defineStore('areaListStore', {
  state: () => ({
    list: [],
  }),

  actions: {
    async fetchArea() {
      return await $apiPublic<any>('/area')
    },

    async list() {
      return await useApiPublic<any>('/area')
    },
  },
})
